import Choices from 'choices.js'

import Component from '../core/Component'
import Nette from '../nette/NetteForms/netteForms'

export const EMITS = {
    CHANGE: 'change',
}

const choicesOptions = {
    itemSelectText: '',
    shouldSort: false,
    searchEnabled: true,
    searchFields: ['label'],
    allowHTML: false,
    addChoices: true,
    addItems: true,
    addItemText: (value) => {
        return `Press Enter to add <b>"${value}"</b>`;
      },
    addItemFilter: (value) => {
        return /^\+\d+$/.test(value)
    },
    callbackOnCreateTemplates: function (strToEl, escapeForTemplate) {
        var itemSelectText = this.config.itemSelectText
        var allowHTML = this.config.allowHTML
        return {
            item: function ({ classNames }, data) {
                return strToEl(
                    `<div class="${String(classNames.item)} ${String(
                        data.highlighted ? classNames.highlightedState : classNames.itemSelectable,
                    )}"
                     data-item
                     role="option"
                     data-id="${String(data.id)}"
                     data-value="${String(escapeForTemplate(allowHTML, data.value))}"
                     ${String(data.active ? 'aria-selected="true"' : '')}
                     ${String(data.disabled ? 'aria-disabled="true"' : '')}>
                  <span class="Flag Flag--${data.element.dataset.flag}"></span>
                  ${String(escapeForTemplate(allowHTML, data.label))}
                </div>`,
                )
            },

            choice: function ({ classNames }, data) {
                return strToEl(
                    `<div class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(
                        data.disabled ? classNames.itemDisabled : classNames.itemSelectable,
                    )}"
                     data-select-text="${String(itemSelectText)}"
                     data-choice
                     ${String(data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable')}
                     data-id="${String(data.id)}"
                     data-value="${String(escapeForTemplate(allowHTML, data.value))}"
                     ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>
                  <span class="Flag Flag--${data.element.dataset.flag}"></span>
                  ${String(escapeForTemplate(allowHTML, data.label))}
                </div>`,
                )
            },
        }
    },
}

const translations = {
    en: {
        searchPlaceholderValue: "Insert telephone prefix",
        customAddItemText: 'Only telephone prefixes can be added',
        addItemText: (value) => `Press Enter to add <b>"${value}"</b>`,
    },
    cs: {
        searchPlaceholderValue: "Vložte telefonní předvolbu",
        customAddItemText: 'Lze přidat pouze telefonní předvolby',
        addItemText: (value) => `Stiskněte Enter pro přidání <b>"${value}"</b>`,
    },
    de: {
        searchPlaceholderValue: "Telefonvorwahl eingeben",
        customAddItemText: 'Nur Telefonvorwahlen können hinzugefügt werden',
        addItemText: (value) => `Drücken Sie Enter, um <b>"${value}"</b> hinzuzufügen`,
    },
}

export default class SelectCountryCodes extends Component {
    constructor(element, options = {}) {
        super(element, options)

        this.ref = {
            select: null,
        }

        this.locale = options.locale || this.handleLocale()
    }

    prepare() {
        const localeTexts = translations[this.locale]

        this.choices = new Choices(this.ref.select, {
            ...choicesOptions,
            searchPlaceholderValue: localeTexts.searchPlaceholderValue,
            customAddItemText: localeTexts.customAddItemText,
            addItemText: localeTexts.addItemText,
            ...this.options.choicesOptions,
        })

        this.ref.select.addEventListener('change', this.handleOptionSelected)
    }

    handleLocale = () => {
        const locale = document.documentElement.lang
        return translations[locale] ? locale : 'en'
    }

    handleOptionSelected = () => {
        this.emit(EMITS.CHANGE, {
            value: this.choices.getValue(true),
        })
        Nette.validateControl(this.ref.select)
    }
}
