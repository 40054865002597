import keycode from 'keycode'

import Component from '../core/Component'
import { loadComponents, removeComponents } from '../core/componentUtils'
import EventBus from '../core/EventBus'
import { disableScrolling, enableScrolling } from '../services/Scroll'
import ScrollContainer from './ScrollContainer'

export const STATES = {
    OPEN: 'is-open',
    LOADING: 'is-loading'
}

export default class PokerResultSidebar extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            background: null,
            layer: null,
            content: null,
            closeButton: null
        }

        this.options = {
            preload: true,
            filterResponse: function(html) {
                const buffer = document.createElement('div')
                buffer.innerHTML = html
                const content = buffer.querySelector('[data-result-content]')
                const data = {}
                if (content) {
                    data.content = content.outerHTML
                }

                const title = buffer.querySelector('[data-result-title]')
                if (title) {
                    data.title = title.innerText
                }
                return data
            }
        }

        this.cache = {}

        this.isActive = false

        this.pageTitle = document.title
    }

    prepare() {
        this.ref.closeButton.addEventListener('click',  this.handleCloseClick)
        if (this.ref.background) {
            this.ref.background.addEventListener('click', this.handleCloseClick)
        }
        if (this.ref.layer) {
            this.scrollContainer = new ScrollContainer(this.ref.layer, {
                media: 'mediumUp',
            })
            if (this.ScrollContainer) {
                this.scrollContainer.prepare()

                if (this.scrollContainer.scrollbar) {
                    this.scrollContainer.scrollbar.scrollTo(0, 0)
                }
            }
        }

        EventBus.on('result:detail:click', this.handleResultDetailClick)
    }

    destroy() {
        this.ref.closeButton.removeEventListener('click',  this.handleCloseClick)
        if (this.ref.background) {
            this.ref.background.removeEventListener('click', this.handleCloseClick)
        }

        this.scrollContainer?.destroy()
        EventBus.off('result:detail:click', this.handleResultDetailClick)

        if (this.isActive) {
            this.close()
        }

    }

    handleResultDetailClick = ({ url }) => {
        if (this.isActive) {
            this.changeContent(url)
        } else {
            this.options.url = url
            this.open()
        }
    }

    handleCloseClick = (event) =>  {
        event.preventDefault()
        this.close()
    }

    handleKeyDown = event => {
        if (keycode(event.keyCode) === 'esc') {
            this.close()
        }
    }

    changeContent(url) {
        this.element.classList.add(STATES.CHANGING)

        Promise.all([
            new Promise(resolve => {
                setTimeout(resolve, 100)
            }),
            this.fetch(url)
        ]).then(result => {
            const response = this.options.filterResponse(result[1])
            this.cache[url] = response.content
            this.setContent(response)
            this.ref.content.scrollTop = 0

            Component.getFromElement(this.ref.inner)?.scrollbar?.setPosition(0, 0)
            this.element.classList.remove(STATES.CHANGING)

            if (this.isActive) {
                const state = {...window.history.state}
                state.url = url
                history.replaceState(state, state.title, state.url)
            }
        })
    }

    open() {
        this.isActive = true
        disableScrolling()

        const eventData = {
            id: this.element.id || this.options.id,
            url: this.options.url
        }

        if (this.options.preload) {
            this.load(true)
        }

        this.element.classList.add(STATES.OPEN)

        document.addEventListener('keydown', this.handleKeyDown)

        this.emit('open', eventData)

        EventBus.emit('modal:open', eventData)

        if (this.options.url && !this.options.autoOpen && !this.options.preload) {
            this.load()
        }
    }

    close() {

        if (!this.isActive) {
            return
        }

        this.isActive = false
        enableScrolling()
        this.element.classList.remove(STATES.OPEN)
        document.removeEventListener('keydown', this.handleKeyDown)

        this.emit('close', {
            id: this.element.id || this.options.id,
            url: this.options.url
        })

        EventBus.emit('modal:close', {
            id: this.element.id || this.options.id,
            url: this.options.url
        })
        document.title = this.pageTitle
    }

    fetch(url) {
        if (url in this.cache) {
            return this.cache[url]
        } else {
            return $.ajax({
                url: url,
                dataType: 'HTML'
            })
        }
    }

    load(preloading = false) {
        this.element.classList.add(STATES.LOADING)
        $.ajax({
            url: this.options.url,
            dataType: 'HTML',
            success: (response) => {
                this.setContent(this.options.filterResponse(response))
            },
            complete: () => {
                this.element.classList.remove(STATES.LOADING)

                if (this.options.autoOpen && !preloading) {
                    this.open()
                }
            },
        })
    }

    setContent({ content, title }) {
        removeComponents(this.ref.content)
        this.ref.content.innerHTML = content
        loadComponents(this.ref.content)
        document.title = title
        $.nette.load()
    }
}
