import Component from '../core/Component'

export const STATES = {
    CLICKABLE: 'is-clickable'
}

export default class Clickable extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            clickableLink: null
        }
    }

    prepare() {
        if (this.ref.clickableLink) {
            this.element.addEventListener('click', this.handleClick)
            this.element.classList.add(STATES.CLICKABLE)
        }
    }

    handleClick = (event) => {
        if (event.target !== this.ref.clickableLink) {
            const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            })

            this.ref.clickableLink.dispatchEvent(event)
        }
    }
}
