import 'console-polyfill'
import './core/Sentry'
// jquery utils
import 'jquery.easing'
import './plugins/nette.ajax'
import './plugins/giant.trigger'
import './plugins/giant.autosubmit'
// nette
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'

import $ from 'jquery'
// loadComponents().then(() => {
//     const id = window.location.hash.replace('#', '')
//     const anchor = document.getElementById(id)
//     console.log({anchor})
//     if (anchor) {
//         setTimeout(() => {
//             let offset = anchor.matches('.Section:not(.Section--appended):not(.Section--bare)') ? 0 : 80
//             offset = anchor.getBoundingClientRect().top + ScrollService.getScroll().y - offset
//             ScrollService.setPosition(0, offset)
//         }, 100)
//     }
// })
import Swup from 'swup'
import swupGtmPlugin from 'swup/plugins/swupGtmPlugin'

import { loadComponents, removeComponents } from './core/componentUtils'
// set basepath of project for webpack
import { basePath, IE, USE_NATIVE_SCROLL } from './core/config'
import EventBus from './core/EventBus'
import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
import Nette from './nette/NetteForms'
import ScrollService from './services/Scroll'
import { queryAll } from './utils/dom'

__webpack_public_path__ = `${basePath}/assets/frontend/js/`

window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

new TouchMouseInputResolver()

if (USE_NATIVE_SCROLL) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

loadComponents()

const scrollStack = []

const pushScrollPosition = () => {
    const location = window.location.href
    const offset = ScrollService.getScroll().y

    scrollStack.push({
        location,
        offset
    })

    if (scrollStack > 10) {
        scrollStack.shift()
    }
}

const popScrollPosition = () => scrollStack.pop()

let currentScroll = null

if (!IE) {
    let skipPopState = false
    EventBus.on('swup:custom:block', () => (skipPopState = true))
    const swup = new Swup({
        LINK_SELECTOR:
            'a[href^="' +
            window.location.origin +
            '"]:not([data-no-swup]):not([data-ref="link"]):not([data-component]), a[href^="/"]:not([data-no-swup]):not([data-ref="link"]):not([data-component]), a[href^="#"]:not([data-no-swup]):not([data-ref="link"]):not([data-component])',
        elements: [
            '#swup',
            '#nav-content',
            '#nav-lang',
            '#footer-content',
            '#modals',
            '#results-detail-wrapper',
            '#poker-footer',
        ],
        debugMode: false,
        doScrollingRightAway: false,
        animateScroll: false,
        preload: true,
        cache: true,
        skipPopStateHandling: (event) => {
            if (skipPopState) {
                skipPopState = false
                return true
            }

            if (event?.state?.source === 'swup') {
                return false
            }

            return true
        },
        plugins: [swupGtmPlugin],
    })

    const isPokerPage = () => {
        return window.location.pathname.match(/^\/(en|de|cs)?\/?poker(\/|$)/)
    }

    swup.on('clickLink', event => {
        if (isPokerPage()) {
            pushScrollPosition()
            EventBus.emit('swup:clicklink', { event })
        }
    })

    // scroll
    swup.on('willReplaceContent', (event) => {
        queryAll('[data-swup]').forEach((element) => {
            removeComponents(element)
        })
    })

    swup.on('contentReplaced', (event) => {
        Nette.reinit()

        const id = window.location.hash.replace('#', '')
        const anchor = document.getElementById(id)

        if (currentScroll) {
            setTimeout(() => {
                ScrollService.setPosition(0, currentScroll.offset)
                currentScroll = null
            }, 0)
        } else if (anchor) {
            setTimeout(() => {
                let offset = anchor.matches('.Section:not(.Section--appended):not(.Section--bare)') ? 0 : 80
                offset = anchor.getBoundingClientRect().top + ScrollService.getScroll().y - offset
                ScrollService.setPosition(0, offset)
            }, 0)
        } else {
            ScrollService.setPosition(0, 0)
        }

        if ($.nette) {
            $.nette.load()
        }

        queryAll('[data-swup]').forEach((element) => {
            loadComponents(element)
        })

        EventBus.emit('swup:contentreplaced')
    })

    swup.on('popState', event => {
        if (isPokerPage()) {
            const settings = popScrollPosition()
            if (settings?.location == window.location.href) {
                currentScroll = settings
            }
        }
    })
}
